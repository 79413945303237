/** @jsx jsx */
import { jsx, Text, Container, Flex, AspectRatio } from 'theme-ui';
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import { Stack } from '../components/grid';

import { ProductView } from '../components/product-view';
const guidSuffix = ''; //process.env.GATSBY_ENVIRONMENT === 'test' ? '_test' : '';

const ProductsPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  return (
    <>
      <GatsbySeo title={'Product Catalog'} noindex={true} nofollow={true} />
      <Container>
        <Stack gap={18}>
          <div>
            <button
              className="snipcart-add-item"
              data-item-id="f5-trial-fonts"
              data-item-price={0}
              data-item-name={data.trials.product_title}
              data-item-url={'/products'}
              data-item-description={data.trials.product_subtitle}
              data-item-max-quantity="1"
              data-item-file-guid={data.trials[`guid${guidSuffix}`]}
            >
              F5 Trial Fonts
            </button>
          </div>
          {data.typefaces.nodes.map((typeface) => (
            <>
              {typeface.published === true &&
                typeface.products &&
                typeface.products.rows &&
                typeface.products.rows.map((product) => {
                  const max = {
                    desktop: typeface.products.maxDesktop,
                    web: typeface.products.maxWeb,
                    app: typeface.products.maxApp,
                    ebook: typeface.products.maxEbook,
                  };

                  return (
                    <ProductView
                      key={product._id}
                      typeface={typeface.slug.current}
                      {...product}
                      max={max}
                    />
                  );
                })}
            </>
          ))}
          {data.nursery.nodes.map((typeface) => (
            <>
              {typeface.published === true &&
                typeface.products &&
                typeface.products.rows &&
                typeface.products.rows.map((product) => {
                  const max = {
                    desktop: typeface.products.maxDesktop,
                    web: typeface.products.maxWeb,
                    app: typeface.products.maxApp,
                    ebook: typeface.products.maxEbook,
                  };

                  return (
                    <ProductView
                      key={product._id}
                      typeface={typeface.slug.current}
                      {...product}
                      max={max}
                    />
                  );
                })}
            </>
          ))}
          {data.nursery.nodes.map((typeface) => (
            <>
              {typeface.published === true && typeface.freebie && typeface.freebie.product ? (
                <ProductView
                  key={typeface.freebie.product._id}
                  typeface={typeface.slug.current}
                  {...typeface.freebie.product}
                />
              ) : null}
            </>
          ))}
        </Stack>
      </Container>
    </>
  );
};

export const query = graphql`
  query ProductsPageQuery {
    trials: sanityPageTrials {
      id
      product_title
      product_subtitle
      guid_test
      guid_live
    }
    typefaces: allSanityTypeface {
      nodes {
        _id
        title
        published
        slug {
          ...SanitySlugFragment
        }

        products {
          ...SanityTypefaceProductsFragment
        }
      }
    }
    nursery: allSanityNursery {
      nodes {
        _id
        title
        published
        slug {
          ...SanitySlugFragment
        }
        freebie {
          product {
            ...SanityProductFragment
          }
        }
        products {
          ...SanityTypefaceProductsFragment
        }
      }
    }
  }
`;

export default ProductsPage;
